<template>
  <div>
    <div>
      <span>
        当前值： {{ display_name }}
      </span>
      <button class="btn btn-primary btn-sm ms-4" @click="show_update_modal">修改</button>
    </div>
    <Modal ref="modify_modal">
      <div>
        选择新的关联项：
      </div>
      <button @click="set_related_object(null)" class="btn btn-secondary btn-sm">清除</button>
      <div class="my-1">
        查找：
      </div>
      <div class="my-1">
        <input type="text" v-model="filter_string" class="form-control" />
      </div>
      <div v-for="v, i in visible_values" class="my-1" :key="i">
        ID:{{ v.id }} {{ show_related_object(v) }}
        <button @click="set_related_object(v)" class="btn btn-primary btn-sm">选择</button>
      </div>
    </Modal>
  </div>
</template>

<script>
export default {
  name: 'RelatedFieldEditor',
  props: ['modelValue', 'field_schema'],
  components: {
  },
  data: function () {
    return {
      possible_values: [],
      filter_string: '',
    };
  },
  computed: {
    visible_values() {
      if (!this.filter_string.length) {
        return this.possible_values;
      }
      var ret = [];
      for (var v of this.possible_values) {
        if (this.show_related_object(v).includes(this.filter_string)) {
          ret.push(v);
        }
      }
      return ret;
    },
    display_name() {
      console.log(this.modelValue);
      return this.show_related_object(this.modelValue);
    },
  },
  methods: {
    show_update_modal() {
      this.filter_string = '';
      this.$refs.modify_modal.show();
    },
    show_related_object(v) {
      if (!v) {
        return '无';
      }
      if (this.field_schema && this.field_schema.metadata.display_field) {
        return v[this.field_schema.metadata.display_field];
      }
      return v;
    },
    set_related_object(v) {
      this.$refs.modify_modal.hide();
      this.$emit("update:modelValue", v);
    },
    async reload() {
      console.log(this.field_schema);
      var url = this.field_schema.metadata.related_schema;
      url = url.replace(/schema\/$/, '');
      var r = await this.$root.api_get_all(url);
      this.possible_values = r;
    },
  },
  mounted() {
    this.reload();
  },
}
</script>

<style scoped>
</style>
