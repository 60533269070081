<template>
  <CModal :visible="visible" @close="close(false)" size="lg">
    <CModalHeader>
      <CModalTitle>{{ title }}</CModalTitle>
    </CModalHeader>
    <CModalBody>
      {{ body }}
      <slot />
      <div v-if="verify_input">
        <hr>
        请输入“{{ verify_input }}”后点击确认
        <input class="form-control" type="text" v-model="verify_input_" id="verify-input">
      </div>
    </CModalBody>
    <CModalFooter>
    <div v-if="close_only">
      <CButton color="secondary" @click="close(false)" id="close-btn">
      关闭
      </CButton>
    </div>
    <div v-else>
      <CButton color="secondary" @click="close(false)">
      <template v-if="cancel_button_text">
        {{ cancel_button_text }}
      </template>
      <template v-else>
        取消
      </template>
      </CButton>
      <CButton color="primary" :disabled="!can_delete"  @click="close(true)" id="submit-btn">
      <template v-if="ok_button_text">
        {{ ok_button_text }}
      </template>
      <template v-else>
        确认
      </template>
      </CButton>
    </div>
    </CModalFooter>
  </CModal>
</template>

<script>
export default {
  name: 'Modal',
  props: ['title', 'body', 'verify_input', 'close_only', 'ok_button_text', 'cancel_button_text'],
  components: {
  },
  data: function () {
    return {
      verify_input_: '',
      visible: false,
      cb: null,
    };
  },
  computed: {
    can_delete: function() {
      return !this.verify_input || this.verify_input == this.verify_input_;
    },
  },
  methods: {
    show: function(cb) {
      this.cb = cb;
      this.visible = true;
    },
    hide: function() {
      this.visible = false;
    },
    close: function(ok) {
      this.visible = false;
      if (this.cb) {
        this.cb(ok);
      }
      this.$emit('close', ok);
    },
  },
  mounted() {
  },
}
</script>

<style scoped>
button {
  margin-right: 1rem;
}
</style>

