import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import GenericManager from './components/generic-manager'
import Modal from './components/modal'

import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
library.add(fas,fab)

const app = createApp(App)
app.use(VueAxios, axios)
app.use(store)
app.use(router)
app.use(CoreuiVue)
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.component('font-awesome-icon', FontAwesomeIcon)

app.component('GenericManager', GenericManager)
app.component('Modal', Modal)

app.mixin({
  data: function() {
    var oss = {
        bucket: 'emblem-archive',
        endpoint: 'oss-cn-shenzhen.aliyuncs.com',
    };
    var estor_url = 'https://emblem-estor.hond.cloud';

    var testenv = false;

    if (testenv) {
      oss = {
        bucket: 'emblem-archive-test',
        endpoint: 'oss-eu-west-1.aliyuncs.com',
      };
      estor_url = 'http://127.0.0.1:3000';
    }
    return {
      // The local address for estor httppost job
      emblem_processor_url: 'http://127.0.0.1:13389',
      estor_url,
      estor_token: '3b1ce86e-f7b6-4f81-ac4e-0f99e990a616',
      oss,
    };
  },
  methods: {
    base_url() {
      return window.location.protocol + "//" + window.location.host;
    },
    sms_verified_action(title, description, action) {
      this.$root.$refs.sms_verify_modal.show(title, description, action);
    },
    show_size(s) {
      var n = s;
      var unit = "B";
      if (s > 1000000000) {
        n = Math.round(s / 100000000) / 10;
        unit = "GB";
      } else if (s > 1000000) {
        n = Math.round(s / 100000) / 10;
        unit = "MB";
      } else if (s > 1000) {
        n = Math.round(s / 100) / 10;
        unit = "KB";
      }

      return n + unit;
    },
    gen_random_string: function(len) {
      var l = len || 10;
      var r = '';

      while (r.length < l) {
        var a = Math.random().toString(36).substring(2, 15);
        r = r + a;
      }
      return r.substring(0, l);
    },
    display_field: function(val, field) {
      var md = field.metadata;
      if (val == null) {
        return '';
      }
      if (md.type == 'datetime') {
        return this.format_timestamp(val);
      }
      if (md.type == 'related') {
        var df = val['display_field'];
        if (df) {
          return val[df];
        }
      }
      if (field.name == 'succeeded') {
        return val ? "成功" : "失败";
      }
      if (md.type == 'boolean') {
        return val ? "是" : "否";
      }
      if (md.multiline) {
        return "<pre>" + val + "</pre>";
      }
      return val;
    },
    format_timestamp: function(ts, dateonly) {
      var d = new Date(ts * 1000);
      if (dateonly) {
        return d.toLocaleDateString();
      } else {
        return d.toLocaleString();
      }
    },
    sleep: function(ms) {
      return new Promise((accept) => {
        setTimeout(accept, ms);
      });
    },
    batch_dir(batch_name, subdir) {
      var r = this.$root.batches_dir + "/" + batch_name;
      if (subdir) {
        r = r + '/' + subdir;
      }
      return r;
    },
    state_file(batch_name) {
      return this.batch_dir(batch_name, "emblem.json")
    },
    async get_batch_state(batch_name) {
      var p = this.state_file(batch_name);
      var r = await this.$root.estor_get(p);
      return r;
    },
    async set_batch_state(batch_name, st) {
      var p = this.state_file(batch_name);
      await this.$root.estor_put(p, st);
    },
  },
})

app.mount('#app')
