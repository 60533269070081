<template>
  <CModal :visible="visible" backdrop="static"  size="lg" @close="visible = false">
    <CModalHeader>
      <CModalTitle>
      {{ object_name }} 详情
      </CModalTitle>
    </CModalHeader>
    <CModalBody>
    <table class="table">
      <tbody>
        <tr v-for="(f, i) in fields" :key="i">
          <td class="field-name">
            {{ f.metadata.verbose_name }}
          </td>
          <td>
            <div class="val" v-html="display_field(cur_obj[f.name], f)"></div>
          </td>
        </tr>
      </tbody>
    </table>
    </CModalBody>
    <CModalFooter>
      <button type="button" class="btn btn-secondary" @click="visible = false">
        关闭
      </button>
    </CModalFooter>
  </CModal>
</template>

<script>
export default {
  name: 'GenericDetailModal',
  props: ['fields', 'id', 'object_name', 'uri'],
  components: {
  },
  data: function () {
    return {
      cur_obj: {},
      error: '',
      visible: false,
    };
  },
  computed: {
  },
  methods: {
    set_obj: function(obj) {
      this.cur_obj = obj;
    },
    show: function() {
      this.visible = true;
    },
  },
  mounted() {
  },
}
</script>

<style>
div.val pre {
  border: 1px solid #999;
  border-radius: .3rem;
  padding: 1rem;
  white-space: pre-wrap;
}
td.field-name {
  min-width: 6rem;
}
</style>

