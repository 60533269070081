<template>
  <Modal :title="title" close_only=1 ref="modal">
    <div class="alert alert-danger">
      {{ description }}
    </div>
    <SmsVerifedAction @ok="commit" :action="description" />
  </Modal>
</template>

<script>
import SmsVerifedAction from './sms-verified-action';

export default {
  name: 'SmsVerifyModal',
  props: [],
  components: {
    SmsVerifedAction,
  },
  data: function () {
    return {
      title: '操作确认',
      description: '确定进行操作吗？',
      action: this.nop,
    };
  },
  computed: {
  },
  methods: {
    nop() {
      console.log("nop");
    },
    commit() {
      if (this.action)
        this.action();
      this.$refs.modal.hide();
    },
    show(title, description, action) {
      this.title = title;
      this.description = description;
      this.action = action;
      this.$refs.modal.show();
    },
  },
  mounted() {
  },
}
</script>

<style scoped>
</style>
