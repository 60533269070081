<template>
  <CSidebar
    position="fixed"
    :unfoldable="sidebarUnfoldable"
    :visible="sidebarVisible"
    @visible-change="
      (event) =>
        $store.commit({
          type: 'updateSidebarVisible',
          value: event,
        })
    "
  >
    <CSidebarBrand>
      <img class="logo" src="/logo.png" alt="">
      <span class="mx-1">
        徽象 -
      </span>
      <span v-if="$root.is_admin">管理员</span><span v-else>租户</span>
    </CSidebarBrand>
    <AppSidebarNav :role="$root.role" />
    <CSidebarToggler
      class="d-none d-lg-flex"
      @click="$store.commit('toggleUnfoldable')"
    />
  </CSidebar>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { AppSidebarNav } from './AppSidebarNav'
import { logoNegative } from '@/assets/brand/logo-negative'
import { sygnet } from '@/assets/brand/sygnet'
export default {
  name: 'AppSidebar',
  components: {
    AppSidebarNav,
  },
  setup() {
    const store = useStore()
    return {
      logoNegative,
      sygnet,
      sidebarUnfoldable: computed(() => store.state.sidebarUnfoldable),
      sidebarVisible: computed(() => store.state.sidebarVisible),
    }
  },
}
</script>

<style scoped>
img.logo {
  width: 32px;
  height: 32px;
  margin-right: 0.5rem;
}
.sidebar-brand {
  justify-content: left;
  padding-left: 20px;
  color: #fff;
}
</style>
