<template>
  <router-view />
  <SmsVerifyModal ref="sms_verify_modal" />
  <CToaster placement="top-end" visible>
    <CToast v-for="(toast, i) in toasts" color="info" :key="i">
      <CToastHeader closeButton>
      <span class="me-auto fw-bold">{{ toast.title }}</span>
      </CToastHeader>
      <CToastBody>
        {{ toast.content }}
      </CToastBody>
    </CToast>
  </CToaster>
</template>

<script>
import { login, logout, userinfo } from './auth';
import SmsVerifyModal from './components/sms-verify-modal';

export default {
  name: '',
  props: [],
  components: {
    SmsVerifyModal,
  },
  data: function () {
    return {
      userinfo: null,
      message_count: null,
      estor_space: 'efs0',
      estor_fs: {
        list: this.$root.estor_list,
      },
      estor_batches_path: ['emblem', 'batches'],
      toasts: [],
      is_in_themblem_domain: document.location.href.startsWith("https://themblem.com/"),
    };
  },
  computed: {
    is_admin: function() {
      return this.role == 'admin';
    },
    tenant_id: function() {
      return !this.is_admin && this.userinfo ? this.userinfo.id : null;
    },
    token: function() {
      return this.userinfo ? this.userinfo.token : null;
    },
    role: function() {
      return this.userinfo ? this.userinfo.role : null;
    },
    estor_api() {
      return this.estor_url + "/api";
    },
    batches_dir() {
      return '/' + this.$root.estor_batches_path.join('/')
    },
    emblem_dir() {
      return '/emblem';
    },
    token_for_estor() {
      return this.userinfo ? this.userinfo.token_for_estor : null;
    },
  },
  methods: {
    login: function(userinfo) {
      logout();
      login(userinfo);
      this.userinfo = userinfo;
    },
    logout: function() {
      logout();
      this.userinfo = null;
    },
    headers: function(headers) {
      if (!headers) {
        headers = {};
      }
      if (this.token) {
        headers['Authorization'] = 'token ' + this.token;
      }
      return headers;
    },
    handle_api_error: function(e) {
      if (e.response.status == 401) {
        this.axios.get('/api/v1/userinfo/').catch((e) => {
          if (e.response.status == 401) {
            var redirect = encodeURI(this.$route.fullPath);
            if (redirect.includes("redirect=") || redirect.includes("/login")) {
              redirect = "/";
            }
            this.$router.push("/pages/login?redirect=" + redirect);
          }
        });
      }
    },
    api_get: function(uri, headers) {
      return this.axios.get(uri, { headers: this.headers(headers) } ).catch(this.handle_api_error);
    },
    api_get_all: async function(uri, headers) {
      var ret = [];
      while (uri) {
        var r = await this.api_get(uri, headers);
        ret = ret.concat(r.data.objects);
        uri = r.data.meta.next;
      }
      return ret;
    },
    api_post: function(uri, data, headers) {
      return this.axios.post(uri, data, { headers: this.headers(headers) } );
    },
    api_put: function(uri, data, headers) {
      return this.axios.put(uri, data, { headers: this.headers(headers) } );
    },
    api_patch: function(uri, data, headers) {
      return this.axios.patch(uri, data, { headers: this.headers(headers) } );
    },
    api_delete: function(uri, headers) {
      return this.axios.delete(uri, { headers: this.headers(headers) } );
    },
    reload_message_count: async function() {
      var r = await this.$root.api_get("/api/v1/message-count/");
      this.message_count = r.data;
    },
    async estor_call(command, options) {
      options = options ? options : {};
      options.command = command;
      var r = await this.axios.post(this.estor_api, options)
      if (!r.data.succeeded) {
        console.log(command, options, r);
        throw new Error("API failed: " + command + " " + options + " " + r.data.error);
      }
      return r.data;
    },
    async estor_stat(path) {
      var r = await this.estor_call("Stat", {
        space: this.estor_space,
        path: path,
      });
      return r.data.data;
    },
    async estor_list(path, offset, limit) {
      if (offset === null)
        offset = null;
      if (!limit)
        limit = null;
      if (path.startsWith("/")) {
        path = path.substr(1);
      }
      var r = await this.estor_call("ListFiles", {
        space: this.estor_space,
        path: path,
        offset,
        limit,
      });
      return r.data.data;
    },
    async estor_delete(path) {
      var url = this.estor_url + "/" + this.estor_space + path;
      console.log(url);
      await this.axios.delete(url);
    },
    async estor_move(src, dest) {
      await this.estor_call("MoveFile", {
        space: this.estor_space,
        path: src,
        dest: dest,
      });
    },
    async estor_copy(src, dest) {
      await this.estor_call("CopyFile", {
        space: this.estor_space,
        path: src,
        dest: dest,
      });
    },
    estor_file_link(p) {
      return this.estor_url + "/" + this.estor_space + p + "?auth=token " + this.estor_token;
    },
    async estor_get(path) {
      var url = this.estor_file_link(path);
      var r = await this.axios.get(url);
      return r.data;
    },
    async estor_put(path, file) {
      var url = this.estor_file_link(path);
      console.log(url);
      var body = file;
      await this.axios.put(url, body, {
          headers: { "content-type": 'application/octet-stream' },
      });
    },
    show_time(t) {
      return t.getFullYear() + "-" + (t.getMonth() + 1) + "-" + t.getDay() + " " +
        t.getHours() + ":" + t.getMinutes();
    },
    notify(title, content) {
      this.toasts.push({
        title,
        content,
      })
    },
  },
  mounted() {
    var ui = userinfo();
    this.userinfo = ui;
    this.reload_message_count();
  },
}
</script>

<style lang="scss">
@import 'styles/style';
html {
  font-size: 16px;
}
</style>
<style>
@import 'styles/animation.css';
@import 'styles/emblem.css';
</style>
