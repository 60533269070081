export default [
  {
    component: 'CNavItem',
    name: '首页',
    to: '/dashboard',
    icon: 'gauge',
  },
  {
    component: 'CNavItem',
    name: '租户管理',
    to: '/tenants',
    icon: 'users',
    role: 'admin',
  },
  {
    component: 'CNavItem',
    name: '产品管理',
    to: '/products',
    icon: 'basket-shopping',
    role: 'tenant',
  },
  {
    component: 'CNavItem',
    name: '内容管理',
    to: '/articles',
    icon: 'folder-open',
  },
  {
    component: 'CNavItem',
    name: '文件管理',
    to: '/assets',
    icon: 'file',
  },
  {
    component: 'CNavItem',
    name: '序列码批次',
    to: '/code-batch',
    icon: 'list',
    role: 'admin',
  },
  {
    component: 'CNavItem',
    name: '序列码管理',
    to: '/code',
    icon: 'list-check',
  },
  {
    component: 'CNavItem',
    name: '特征管理',
    to: '/code-feature',
    icon: 'list-check',
    role: 'admin',
  },
  {
    component: 'CNavItem',
    name: '扫码记录',
    to: '/scan-data',
    icon: 'basket-shopping',
  },
  {
    component: 'CNavItem',
    name: '系统日志',
    to: '/system-log',
    icon: 'clock-rotate-left',
    role: 'admin',
  },
  {
    component: 'CNavItem',
    name: '小程序页面定制',
    to: '/mini-program-content',
    icon: ['fab', 'weixin'],
  },
  {
    component: 'CNavItem',
    name: '小程序摄像头配置',
    to: '/camera-rule',
    icon: 'camera',
  },
  {
    component: 'CNavItem',
    name: '系统',
    to: '/system-settings',
    icon: 'gears',
    role: 'admin',
  },
  {
    component: 'CNavGroup',
    name: '防伪备案',
    to: '/estor',
    icon: 'diagram-next',
    role: 'admin',
    items: [
      {
        component: 'CNavItem',
        name: '系统总览',
        to: '/estor',
      },
      {
        component: 'CNavItem',
        name: '图像导入',
        to: '/estor/import',
      },
      {
        component: 'CNavItem',
        name: '数据管理',
        to: '/estor/batches',
      },
      {
        component: 'CNavItem',
        name: '任务管理',
        to: '/estor/jobs',
      },
      {
        component: 'CNavItem',
        name: '浏览文件',
        to: '/estor/files',
      },
      {
        component: 'CNavItem',
        name: '磁盘管理',
        to: '/estor/disks',
      },
      {
        component: 'CNavItem',
        name: '策略配置',
        to: '/estor/config',
      },
      {
        component: 'CNavItem',
        name: '备份恢复',
        to: '/estor/backup-restore',
      },
    ],
  },
]
