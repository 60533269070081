
export function login(userinfo) {
  localStorage.setItem('userinfo', JSON.stringify(userinfo));
}

export function userinfo() {
  var r = localStorage.getItem('userinfo');
  if (r && r.length) {
    return JSON.parse(r)
  }
  return null;
}

export function logout() {
  localStorage.removeItem('userinfo');
}

export function authenticated() {
  return userinfo() !== null;
}
