<template>
  <CHeader position="sticky" class="mb-4" :class="{ green: !$root.is_in_themblem_domain }">
    <CContainer fluid>
      <CHeaderToggler class="ps-1" @click="$store.commit('toggleSidebar')">
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler>
      <CHeaderNav class="d-none d-md-flex me-auto">
        <CNavItem>
          <CNavLink href="/dashboard">
            徽象溯源防伪平台
          </CNavLink>
        </CNavItem>
      </CHeaderNav>
      <CHeaderNav>
        <CNavItem>
          <CNavLink href="/inbox">
            <font-awesome-icon :class="{newmsg: num_new_message > 0}" icon="envelope"/>
            <span v-if="num_new_message > 0">({{ num_new_message }})</span>
          </CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink href="/settings">账户设置</CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink href="#" @click="logout">
          退出登录
          </CNavLink>
        </CNavItem>
      </CHeaderNav>
    </CContainer>
  </CHeader>
</template>

<script>
export default {
  name: 'AppHeader',
  props: [],
  components: {
  },
  data: function () {
    return {
    };
  },
  computed: {
    num_new_message: function() {
      if (this.$root.message_count)
        return this.$root.message_count.new;
      return 0;
    },
  },
  methods: {
    logout: function() {
      this.$root.logout();
      this.$router.push("/pages/login");
    },
  },
  mounted() {
  },
}
</script>

<style scoped>
.newmsg {
  color: blue;
}
.green {
  background-color: #4c4;
}
</style>

