<template>
  <div>
    <div v-if="!verfied">
      <div class="input-group">
        <input class="form-control" type="text" v-model="code"/>
        <button class="btn btn-primary" :disabled="request_cooldown" @click="request">
          发送验证码
          <span v-if="request_cooldown">({{ request_cooldown }})</span>
        </button>
      </div>
      <button class="btn btn-primary my-4" :disabled="!code" @click="verify">
        提交
      </button>
    </div>
    <div v-else>
      <button class="btn btn-danger">{{ title }}</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SmsVerifedAction',
  props: ['title', 'action'],
  emits: ['ok'],
  components: {
  },
  data: function () {
    return {
      verified: false,
      code: '',
      request_cooldown: 0,
      request_id: 0,
    };
  },
  computed: {
  },
  methods: {
    async request() {
      this.code = '';
      this.request_cooldown = 30;
      var r = await this.$root.api_post("/api/v1/sms-verify-request/", {
        action: this.action,
      });
      console.log(r);
      this.request_id = r.data.request_id;
      this.$root.notify("验证码", "验证码已发送，请稍等……");
    },
    reset() {
      this.verified = false;
      this.sms = '';
    },
    async verify() {
      var r = await this.$root.api_post("/api/v1/sms-verify-commit/", {
        code: this.code,
        request_id: this.request_id,
      });
      console.log(r.data);
      if (r.data.ok) {
        this.$emit("ok");
      } else {
        this.$root.notify("验证码", "验证失败，请稍后重试");
      }
    },
    cooldown_tick() {
      if (this.request_cooldown)
        this.request_cooldown -= 1;
    },
  },
  mounted() {
    setInterval(this.cooldown_tick, 1000);
  },
}
</script>

<style scoped>
</style>
