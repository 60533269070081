<template>
  <editor
    v-if="ready"
    api-key="38mx763ade0rxui8uiun6tourzf3bb4rlq2deh5hvtsv41rp"
    :init="config"
    ref="editor"
    :value="value"
    @input="handle_input"
    />
</template>

<script>
import Editor from '@tinymce/tinymce-vue'

export default {
  name: 'RichTextEditor',
  props: ['value', 'content_css'],
  components: {
    'editor': Editor
  },
  data: function () {
    return {
      innervalue: '',
      ready: false,
      config: {
        language: 'zh_CN',
        height: 600,
        plugins: 'lists link image table code help wordcount',
        toolbar: 'undo redo | styles | bold italic underline forecolor align | link image',
        images_upload_url: '/api/v1/asset/?token=' + this.$root.token,
        content_css: this.content_css,
      }
    };
  },
  computed: {
  },
  methods: {
    handle_input() {
      this.$emit('input', this.innervalue);
    },
    load_image_list: async function() {
      var r = await this.$root.api_get("/api/v1/image-list/?token=" + this.$root.token);
      this.config.image_list = r.data.images;
      this.ready = true;
    },
  },
  mounted() {
    this.load_image_list();
  },
}
</script>

<style>
</style>
